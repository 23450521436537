import { HOST_API_KEY } from "@/globalConfig"

type ApiMethods = "GET" | "POST" | "DELETE" | "PATCH" | "PUT"
var modules = {
  USER_AUTH: "user",
  USER: "users",
  CLEANS: "cleans",
  CLEANER_APP: "cleanerapp",
  TICKET: "ticket",
  EXTERNAL_CONTRACTOR: "ext_contractor",
  EXTERNAL_CONTRACTOR_MAINTAINANCE: "external-contractor/maintainance",
  AWS: "aws",
  ADDITIONAL_SERVICE: "additional-service",
  INTERNAL: "internal",
  FORM: "forms",
}
export const ROUTES = {
  login: "login",
  getProperty: "get_property",
  getCleansCounts: "cleans_count",
  getCleansCountsv2: "cleans_count_v2",
  getCleanTabel: "get_cleans",
  getCleanTicketsv2: "get_cleans_v2",
  getCleanById: "clean",
  postUpdateClean: "cleans_update",
  postDeleteCleanNotes: "delete",
  getMaintainceCount: "counters",
  getMaintananceDashboardCountv2: "counters_v2",
  getMaintainanceTable: "tickets",
  getMaintainanceTablev2: "tickets_v2",
  getExternalEmployees: "external-employees",
  postEditCleans: "edit_clean",
  getMaintainceModal: "modal",
  getAssigneeOptions: "assignee-options",
  getRecentMainatainanceMessages: "recent-messages",
  updateAssignTicket: "assign-ticket",
  getMaintainanceTicketById: "ticket_guest_details",
  getMaintainanceComment: "ticket-comments",
  postMaintainanceComment: "comment",
  getMaintainanceTicketAssignForm: "ticket-asssign-form",
  postUploadImages: "image-aws",
  postTicketAssign: "ticket_assign",
  getUserDetails: "userDetails",
  postCleanReview: "clean-review-status/update",
  readTicket: "mark_read",
  updateCleanTable: "cleans-update-table",
  getBookingNotes: "notes/booking-notes",
  getEmployeesv2: "/v2/employees",
  updateNotRequired: "/update/not-required-status",

  allComments: "comments",
  createMaintenanceTicket: "/maintenance/create-ticket",
  postMaintenanceFormSubmission: "maintenance-form-submission",
  maintenanceTicketRequest: "/maintenance-request/ticket-details",

  getAvailCalendar: "/availability-calendar",

  createCleansTicket: `/create-ticket`,
  cleansTicketRequest: "/cleans/ticket-details",
  postCleansFormSubmission: "cleans-form-submission",
  editClean: "edit_clean",
  addNote: "/add-note",
}
export const API_END_POINT: Record<ApiMethods, Record<string, string>> = {
  GET: {
    [ROUTES.getUserDetails]: `${HOST_API_KEY}${modules.USER_AUTH}/userDetails`,
    [ROUTES.getCleansCounts]: `${HOST_API_KEY}${modules.CLEANS}/${modules.EXTERNAL_CONTRACTOR}/cleans_count`,
    [ROUTES.getCleanById]: `${HOST_API_KEY}${modules.CLEANER_APP}/clean`,
    [ROUTES.getMaintainceCount]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/main-page/counters`,
    [ROUTES.getMaintainceModal]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/main-page/modal`,
    [ROUTES.getExternalEmployees]: `${HOST_API_KEY}${modules.USER}/external-employees`,
    [ROUTES.getAssigneeOptions]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/main-page/modal/assignee-options`,
    [ROUTES.getRecentMainatainanceMessages]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/main-page/recent-messages`,
    [ROUTES.getMaintainanceTicketById]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/ticket_guest_details`,
    [ROUTES.getMaintainanceComment]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/ticket-comments`,
    [ROUTES?.getMaintainanceTicketAssignForm]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/ticket-asssign-form`,
    [ROUTES.getProperty]: `${HOST_API_KEY}${modules.ADDITIONAL_SERVICE}/get_property`,
    [ROUTES.allComments]: `${HOST_API_KEY}${modules.TICKET}/comments`,
    [ROUTES.maintenanceTicketRequest]: `${HOST_API_KEY}${modules.TICKET}/maintenance/ticket_details`,

    [ROUTES.cleansTicketRequest]: `${HOST_API_KEY}${modules.CLEANS}/ticket_details`,
  },
  POST: {
    [ROUTES.login]: `${HOST_API_KEY}${modules.USER_AUTH}/login`,
    [ROUTES.postUpdateClean]: `${HOST_API_KEY}${modules.CLEANS}/ext_contractor/cleans_update`,
    [ROUTES.postDeleteCleanNotes]: `${HOST_API_KEY}${modules.CLEANER_APP}/notes/delete`,
    [ROUTES.postEditCleans]: `${HOST_API_KEY}${modules.CLEANS}/${modules.EXTERNAL_CONTRACTOR}/edit_clean`,
    [ROUTES.editClean]: `${HOST_API_KEY}${modules.CLEANER_APP}/edit_clean`,
    [ROUTES.addNote]: `${HOST_API_KEY}${modules.CLEANER_APP}/add_note`,
    [ROUTES.postMaintainanceComment]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/comments`,
    [ROUTES.postUploadImages]: `${HOST_API_KEY}${modules.AWS}/image-aws`,
    [ROUTES.postTicketAssign]: `${HOST_API_KEY}${modules.TICKET}/${modules.EXTERNAL_CONTRACTOR_MAINTAINANCE}/ticket_assign`,
    [ROUTES.postCleanReview]: `${HOST_API_KEY}cleans/clean-review-status/update`,
    [ROUTES.getCleanTabel]: `${HOST_API_KEY}${modules.CLEANS}/${modules.EXTERNAL_CONTRACTOR}/get_cleans`,
    [ROUTES.getCleanTicketsv2]: `${HOST_API_KEY}${modules.CLEANS}/${modules.EXTERNAL_CONTRACTOR}/get_cleans_v2`,
    [ROUTES.getMaintainanceTable]: `${HOST_API_KEY}${modules.TICKET}/${modules?.EXTERNAL_CONTRACTOR_MAINTAINANCE}/main-page/${ROUTES.getMaintainanceTable}`,
    [ROUTES.getMaintainanceTablev2]: `${HOST_API_KEY}${modules.TICKET}/ticket_v2`,
    [ROUTES.readTicket]: `${HOST_API_KEY}${modules.TICKET}/${ROUTES.readTicket}`,
    [ROUTES.getBookingNotes]: `${HOST_API_KEY}${ROUTES.getBookingNotes}`,
    [ROUTES.getCleansCountsv2]: `${HOST_API_KEY}${modules.CLEANS}/${modules.EXTERNAL_CONTRACTOR}/cleans_count_v2`,
    [ROUTES.getMaintananceDashboardCountv2]: `${HOST_API_KEY}${modules.TICKET}/external-contractor/maintainance/main-page/counters_v2`,
    [ROUTES.getEmployeesv2]: `${HOST_API_KEY}${modules.USER}/v2/employees`,
    [ROUTES.updateNotRequired]: `${HOST_API_KEY}${modules.INTERNAL}/update/not-required-status`,

    [ROUTES.allComments]: `${HOST_API_KEY}${modules.TICKET}/comments`,
    [ROUTES.createMaintenanceTicket]: `${HOST_API_KEY}${modules.TICKET}/maintenance/create_ticket`,
    [ROUTES.postMaintenanceFormSubmission]: `${HOST_API_KEY}${modules.FORM}/maintenance-form-submission`,
    [ROUTES.getAvailCalendar]: `${HOST_API_KEY}property_reservationDetails/availability-calendar`,

    [ROUTES.createCleansTicket]: `${HOST_API_KEY}${modules.CLEANS}/create_ticket`,
    [ROUTES.postCleansFormSubmission]: `${HOST_API_KEY}${modules.CLEANS}/cleans-form-submission`,
  },
  PATCH: {
    [ROUTES.updateAssignTicket]: `${HOST_API_KEY}${modules.TICKET}/${modules.EXTERNAL_CONTRACTOR_MAINTAINANCE}/main-page/modal/assign-ticket`,
    [ROUTES.updateCleanTable]: `${HOST_API_KEY}${modules.CLEANER_APP}/cleans-update-table`,
  },
  DELETE: {},
  PUT: {},
}

export default API_END_POINT
